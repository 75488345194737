<template>
  <div>
    <div v-if="!showDialog" class="page">
      <!--查询框-->
      <div v-if="!showDialog" class="search-box section page-header" style="margin-bottom: 1px;">
        <el-form ref="searchForm" :inline="true" :model="query" size="medium">
          <el-form-item prop="parkingLotName">
            <!--          <SuggestParking v-model="query.parkingLotId" />-->
            <el-input
                v-model="query.parkingLotName"
                placeholder="请输入车场名称查询"
                prefix-icon="el-icon-search"
                class="width260 margin-right10 margin-left38"
            />
          </el-form-item>
          <el-form-item prop="type">
            <el-select
                v-model="query.type"
                placeholder="请选择停车场类型"
            >
              <el-option label="全部停车场" value="2"></el-option>
              <el-option label="路内停车场" value="1"></el-option>
              <el-option label="路外停车场" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="goSearch">搜索</el-button>
            <el-button @click="resetForm">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!--信息统计框-->
      <div v-if="!showDialog" class="search-box section page-header height80 padding20-32">
        <div class="content" style="margin-left: 0;">
          <img src="@/assets/images/monitor/ccsl.png" class="width29 height29">
          <div class="content-item">
            <div class="title">车场总数</div>
            <div class="text">{{ parkingLotTotal.parkingLotTotalCount }}</div>
          </div>
        </div>
        <div class="content">
          <img src="@/assets/images/monitor/bwsl.png" class="width29 height29">
          <div class="content-item">
            <div class="title">路内车场</div>
            <div class="text">{{ parkingLotTotal.parkingLotInnerCount }}</div>
          </div>
        </div>
        <div class="content">
          <img src="@/assets/images/monitor/lwcc.png" class="width29 height29">
          <div class="content-item">
            <div class="title">路外车场</div>
            <div class="text">{{ parkingLotTotal.parkingLotOutCount }}</div>
          </div>
        </div>
        <div class="content">
          <img src="@/assets/images/monitor/bwzs.png" class="width29 height29">
          <div class="content-item">
            <div class="title">泊位总数</div>
            <div class="text">{{parkingSpaceTotal.spaceTotalCount}}</div>
          </div>
        </div>
        <div class="content">
          <img src="@/assets/images/monitor/zybw2.png" class="width29 height29">
          <div class="content-item">
            <div class="title">占用泊位</div>
            <div class="text">{{parkingSpaceTotal.spaceUseCount}}</div>
          </div>
        </div>
        <div class="content">
          <img src="@/assets/images/monitor/kxbw.png" class="width29 height29">
          <div class="content-item">
            <div class="title">空闲泊位</div>
            <div class="text">{{ parkingSpaceTotal.spaceEmptyCount }}</div>
          </div>
        </div>
        <div class="content">
          <img src="@/assets/images/monitor/zzl.png" class="width29 height29">
          <div class="content-item">
            <div class="title">周转率</div>
            <div class="text">{{ parkingSpaceTotal.parkingRate }}</div>
          </div>
        </div>
      </div>
      <div v-if="!showDialog" v-loading="isLoading" class="content-box section">
        <div class="font-size16 margin-bottom20" style="color: black;font-weight: bold;">车场全局视图</div>
        <div class="parking-items">
          <div
              v-for="item in dataList"
              :key="item.id"
              class="parking-item"
          >
            <div class="parking-item-head">
              <span class="parking-name">{{ item.name }}</span>
              <div v-if="item.parkingLotType==1" class="parking-type road-in">路内</div>
              <div v-else class="parking-type road-out">路外</div>
            </div>
            <div style="width: 100%;">
              <div class="padding17-20">
                <div class="parking-item-content margin-top50">
                  <div style="display: flex;justify-content: space-between;">
                    <div style="display: flex;flex: 1;align-items: center;">
                      <div class="width6 height6 border-radius6" style="background: #C5D1CB;"></div>
                      <div class="margin-left4 font-size14" style="color: #5F666C;">泊位总数</div>
                      <div class="margin-left12 width40 height14">
                        <div class="font-size14" style="color: black;font-weight: bold;text-align: left;">{{ item.spaceTotalCount }}</div>
                      </div>
                    </div>
                    <div style="display: flex;flex: 1;align-items: center;">
                      <div class="width6 height6 border-radius6" style="background: #C5D1CB;"></div>
                      <div class="margin-left4 font-size14" style="color: #5F666C;">周转率</div>
                      <div class="margin-left12 width40 height14">
                        <div class="font-size14" style="color: black;font-weight: bold;text-align: left;">{{ item.parkingRate }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="parking-item-content margin-top20">
                  <div class="padding-bottom19"
                      style="display: flex;justify-content: space-between;border-bottom: 1px #5F666C dashed;">
                    <div style="display: flex;flex: 1;align-items: center;">
                      <div class="width6 height6 border-radius6" style="background: #C5D1CB;"></div>
                      <div class="margin-left4 font-size14" style="color: #5F666C;">空闲泊位</div>
                      <div class="margin-left12 width40 height14">
                        <div class="font-size14" style="color: black;font-weight: bold;text-align: left;">{{ item.spaceEmptyCount }}</div>
                      </div>
                    </div>
                    <div style="display: flex;flex: 1;align-items: center;">
                      <div class="width6 height6 border-radius6" style="background: #C5D1CB;"></div>
                      <div class="margin-left4 font-size14" style="color: #5F666C;">占用泊位</div>
                      <div class="margin-left12 width40 height14">
                        <div class="font-size14" style="color: black;font-weight: bold;text-align: left;">{{ item.spaceUseCount }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="parking-item-content margin-top20">
                  <div style="display: flex;justify-content: space-between;">
                    <div style="display: flex;flex: 1;align-items: center;">
                      <div class="width6 height6 border-radius6" style="background: #C5D1CB;"></div>
                      <div class="margin-left4 font-size14" style="color: #5F666C;">今日应收</div>
                      <div class="margin-left12 width40 height14">
                        <div class="font-size14" style="color: black;font-weight: bold;">{{ item.receivableAmount }}</div>
                        <!--                      <el-badge style="margin-top: 10px;margin-right: 40px;top:-10px;right: 0;" value="1ST">-->
                        <!--                        <div style="color: black;font-size: 14px;font-weight: bold;">{{ item.receivableAmount }}</div>-->
                        <!--                      </el-badge>-->
                      </div>
                    </div>
                    <div style="display: flex;flex: 1;align-items: center;">
                      <div class="width6 height6 border-radius6" style="background: #C5D1CB;"></div>
                      <div class="margin-left4 font-size14" style="color: #5F666C;">今日实收</div>
                      <div class="margin-left12 width40 height14">
                        <div class="font-size14" style="color: black;font-weight: bold;">{{ item.transactionAmount }}</div>
                        <!--                      <el-badge style="margin-top: 10px;margin-right: 40px;top:-10px;right: 0;" value="1ST">-->
                        <!--                        <div style="color: black;font-size: 14px;font-weight: bold;">{{ item.transactionAmount }}</div>-->
                        <!--                      </el-badge>-->
                      </div>
                    </div>
                  </div>
                </div>
                <!--              <div class="parking-item-content" style="margin-top: 20px;">-->
                <!--                <div style="display: flex;justify-content: space-between;">-->
                <!--                  <div style="display: flex;flex: 1;align-items: center;">-->
                <!--                    <div style="width: 6px;height: 6px;border-radius: 6px;background: #C5D1CB;"></div>-->
                <!--                    <div style="margin-left: 4px;color: #5F666C;font-size: 14px;">人员配置</div>-->
                <!--                    <div style="margin-left: 11px;width: 38px;height: 14px;">-->
                <!--                      <div style="color: black;font-size: 14px;font-weight: bold;text-align: left;">9</div>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                  <div style="display: flex;flex: 1;align-items: center;">-->
                <!--                    <div style="width: 6px;height: 6px;border-radius: 6px;background: #C5D1CB;"></div>-->
                <!--                    <div style="margin-left: 4px;color: #5F666C;font-size: 14px;">设备配置</div>-->
                <!--                    <div style="margin-left: 11px;width: 38px;height: 14px;">-->
                <!--                      <div style="color: black;font-size: 14px;font-weight: bold;text-align: left;">29</div>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--              </div>-->
                <div class="parking-item-content height29 font-size12 margin-top23"
                     style="cursor: pointer;background: #ECF3FF;color: #0768FD; display: flex;align-items: center;justify-content: center;font-weight: bold;"
                     @click="go(item)">
                  查看车场详情
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pagination-box">
          <el-pagination
              :current-page="pagination.pageNo"
              :page-size="pagination.pageSize"
              :total="pagination.totalSize"
              background
              layout="sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <FullScreenDialog ref="showPsMonitorDetail">
      <ParkingSpaceDetail
          :go-back="hideDialog"
          :parkingId="currentRow.id"
          :parkingName="currentRow.name"
      />
    </FullScreenDialog>
  </div>

</template>

<script>
import BaseMixin from "@/mixins/base";
import {
  parkingLotPage,
  // getParkingSpaceByParkingLot,
  getParkingSpaceTotal,
  getParkingLotTotal,
} from "@/api/monitor";
import FullScreenDialog from "@/components/FullScreenDialog";
import ParkingSpaceDetail from "./parkingspace_detail.vue";
import {
  checkToken,
} from "@/api/common";
import {MessageBox} from "element-ui";

export default {
  name: "monitor-parkingspace",
  mixins: [BaseMixin],
  components: {
    FullScreenDialog,
    ParkingSpaceDetail,
  },
  data() {
    return {
      showDialog: false,
      isLoading: false,
      query: {
        // parkingLotId: "",
        // parkingLotType: "",
        parkingLotName:'',
        type:'2',
        regionId: "530129",
      },
      dataList: [],
      parkingSpaceTotal:{},
      parkingLotTotal:{},
      parkSpaceCount: "0", //泊位总数
      currentRow: {},
    };
  },
  methods: {
    //查询
    goSearch() {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 10;
      this.search();
    },
    getProgress(item) {
      const totalPsCount = item.psTotalCount;
      const usedPsCount = item.inUsePsCount;

      if (!usedPsCount) {
        return "0%";
      }

      return usedPsCount / totalPsCount + "%";
    },
    async search() {
      checkToken()
          .then(async res => {
            console.log('checkToken', res)
            if (!res || res.code !== 30) {
              MessageBox.alert('您的登录信息已超时，请重新登录！', '系统提示', {
                confirmButtonText: '重新登录',
                callback: () => {
                  window.localStorage.clear()
                  window.$vue.$router.push('/login')
                }
              });
            } else {
              this.isLoading = true;
              let params = this.paramFormat(this.query);
              // let res = await getParkingSpaceByParkingLot(params);
              let res = await parkingLotPage(params);
              this.isLoading = false;
              if (res && res.code === 30 && res.result) {
                const returnObject = res.returnObject;
                this.dataList = returnObject.records;
                this.pagination.totalSize = returnObject.total;
                this.pagination.totalPages = returnObject.pages;
                this.parkSpaceCount = returnObject.psTotalCount;
              }

              params = this.paramFormat(this.query);
              res = await getParkingSpaceTotal(params);
              this.isLoading = false;
              if (res && res.code === 30 && res.result) {
                this.parkingSpaceTotal = res.returnObject;
              }
              res = await getParkingLotTotal(params);
              if (res && res.code === 30 && res.result) {
                this.parkingLotTotal = res.returnObject;
              }
            }
          })

    },
    //重置查询条件
    resetForm() {
      this.query = {
        parkingLotName:'',
        type:'2',
        regionId: "530129",
      };
      this.goSearch();
    },
    //跳转详情页
    go(item) {
      this.currentRow = item;
      this.showDialog = true;
      console.log(item)
      this.$refs.showPsMonitorDetail.show();
    },
    hideDialog() {
      this.showDialog = false;
      this.$refs.showPsMonitorDetail.hide();
    },
  },
};
</script>

<style lang="less" scoped>
.parking-items {
  display: flex;
  flex-wrap: wrap;
  min-width: 1200px;

  .parking-item {
    width: calc(20% - 16px);
    //border: 1px solid #e6e6e6;
    //border-radius: 4px;
    min-height: 234px;
    margin-bottom: 20px;
    margin-right: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);

    .parking-item-head {
      height: 44px;
      width: 100%;
      //border-bottom: 1px solid #e6e6e6;
      position: absolute;
      top: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      color: black;
      font-size: 16px;
      font-weight: bold;
      background: #F2FAF6;

      img {
        margin: 0 4px 0 2px;
      }

      .parking-name {
        margin-left: 20px;
      }
    }

    .parking-type {
      width: 49px;
      height: 20px;
      color: #fff;
      font-size: 12px;
      border-radius: 21px;
      line-height: 20px;
      text-align: center;
      text-indent: 6px;
      margin-right: 16px;

      &.road-in {
        background: #0768FD;
      }

      &.road-out {
        background: #F4933C;
      }
    }

    .ps-used-rate {
      height: 8px;
      border-radius: 8px;
      background: #ebeef5;
      position: absolute;
      bottom: 14px;
      left: 12px;
      right: 12px;

      .ps-used-progress {
        height: 100%;
        width: 80%;
        border-radius: 8px;
        background: #0768FD;
      }

      .ps-used-tips {
        position: absolute;
        min-width: 50px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
        color: #fff;
        background: #0768FD;
        border-radius: 4px;
        font-size: 12px;
        bottom: 20px;
        left: 0;
        z-index: 100;

        &::after {
          width: 12px;
          height: 12px;
          background: #0768FD;
          content: "";
          transform: rotate(45deg);
          position: absolute;
          bottom: -6px;
          z-index: 99;
        }
      }
    }

    .parking-item-content {
      width: 100%;
      text-align: center;
      font-size: 14px;

      .ps-rate {
        font-size: 14px;
        margin-bottom: 10px;

        .free {
          font-size: 24px;
        }
      }

      .ps-label {
        color: #80838f;
      }
    }

    &:nth-child(5n) {
      margin-right: 0;
    }
  }
}
</style>
